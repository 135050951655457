import { Modal, Notification, STATUS_TYPES, VALIDATORS, generateResolver, yup, FileInput, MultipleNestedDropdown, VoicemailPlayback } from "dyl-components";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, Icon, Popup, Input, Segment } from "semantic-ui-react";
import './Audio.scss'
import { useDispatch, useSelector } from "react-redux";
import { PhoneUtil, FileUtils } from "utils";
import pbxConfigActions from "actions/pbx_config";
import uploadActions from "actions/upload";

const Audio = ({ 
    onClose, 
    onReload, 
    dropzoneRef = React.createRef(), 
    isEditSoundsModal,
    open
}) => {
    const dispatch = useDispatch();

    const [destinationOptions, setDestinationOptions] = useState([]);
    const [flatDestinationOptions, setFlatDestinationOptions] = useState([]);
    const [destinationOptionsDropdown, setDestinationOptionsDropdown] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    const { destinations, isReadingDestinations, usersWithExtensions, isReadingUsersWithExtensions, sound, isReadingSound } = useSelector((state) => state.pbx_config);

    const { control, formState: { isValid, isDirty }, getValues, watch, reset, setValue, trigger, setError } = useForm({
        mode: 'onChange',
        defaultValues: {
            apply_to: sound?.sound_category === "music" 
                ? sound?.in_use?.map(destination => destination.alias ? `${destination.label} (Ext. ${destination.alias})` : destination.label) 
                        : sound?.sound_category === "vmdrop" 
                            ? sound?.in_use?.map(user => user.user_id)
                                : sound?.in_use?.map(destination => destination.destination_id) || [],
            sound_category: sound?.sound_category || "general",
            label: sound?.label || "",
            attachments: [],
            isEdit: false,
        },
        resolver: generateResolver({
            sound_category: yup.string().required("This field is required"),
            label: VALIDATORS.TEAM_NAME().required("This field is required"),
            attachments: yup.array().when("isEdit", {
                is: false,
                then: schema => schema.test("max_file_size_limit", `Total size exceeds ${FileUtils.getMaxFileSize("phone_system")}MB`, (value) => {
                    return FileUtils.isBelowFileSizeLimit(value, "phone_system");
                }).test("one_file_limit", 'This field is required', (value) => {
                    return value.length > 0 && value.length < 2;
                })
            })
        })
    });

    const onCloseModal = () => {
        onClose();
        reset();
        dispatch(pbxConfigActions.removeSound());
    }

    const watchedType = watch("sound_category");
    const soundTypes = isEditSoundsModal && watchedType !== "ivr" ? PhoneUtil.SOUND_TYPES.filter(type => type.key === sound.sound_category || type.key === "general") : PhoneUtil.SOUND_TYPES;
    
    const applyToOptions = (apply_to) => {
        return apply_to.map(apply => (flatDestinationOptions.find(option => (option.value === apply)).id))
    }

    const onCheckDuplicatedName = async () => {
        const label = getValues("label");
        try {
            if((!isEditSoundsModal) || (isEditSoundsModal && sound?.label !== label)){
                const isDuplicated = await dispatch(pbxConfigActions.isDuplicatedName({label, type: "sound"}));
                if (isDuplicated) {
                    setError("label", { type: "unique", message: "Name already exists!" })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    const onAddAudio = async () => {
        const { apply_to, attachments, label, sound_category } = getValues();
        const type = watchedType === "music";
        let applyToId;
        if(type){
            applyToId = applyToOptions(apply_to);
        }
        try {
            await setIsUploading(true);
            const uploadedFile = await dispatch(uploadActions.uploadFiles(attachments, "phone_system"));
            await dispatch(pbxConfigActions.createSound({ 
                apply_to: type ? applyToId : apply_to, 
                label, 
                sound_category,
                size: attachments[0].size,
                location: uploadedFile[0]
            }));
            await setTimeout(()=>{
                Notification.alert('Audio added Successfully!', STATUS_TYPES.SUCCESS);
                setIsUploading(false);
                onReload();
                onClose();
                trigger();
                reset();
            }, 3000)
        } catch (e) {
            console.log(e);
            setIsUploading(false);
            Notification.alert('Failed to add audio', STATUS_TYPES.ERROR);
        }
    }

    const onEditAudio = async (id) => {
        const { apply_to, label, sound_category } = getValues();
        const type = watchedType === "music";
        let applyToId;
         if(type){
            applyToId = applyToOptions(apply_to);
        }
        try {
            await dispatch(pbxConfigActions.updateSound(id, { 
                apply_to: type ? applyToId : apply_to, 
                label, 
                sound_category,
            }));
            Notification.alert('Audio edited Successfully!', STATUS_TYPES.SUCCESS);
            onReload();
            onClose();
            trigger();
            reset();
        } catch (e) {
            console.log(e);
            setIsUploading(false);
            Notification.alert('Failed to edit audio', STATUS_TYPES.ERROR);
        }
    }

    useEffect(() => {
        if (destinations && watchedType === "music") {
            let flatOptions = [];
            let flatOptionsText = [];
            const destinationTypesAux = [...PhoneUtil.DESTINATION_TYPES_OPTIONS];
            const destinationOptionsAux = destinationTypesAux.map((destinationType) => {
                let { key } = destinationType; 
                const options = destinations[key]?.map(({destination_id, label, alias}) => (
                    {id: destination_id, value: alias ? `${label} (Ext. ${alias})`: label, text: alias ? `${label} (Ext. ${alias})`: label}
                )) || []
                return {...destinationType, options};
            })
            const filterOptions = destinationOptionsAux.filter(destination => destination.options.length !== 0);
            filterOptions.forEach(destination => {
                destination.options.forEach(dest => {
                    flatOptions.push(dest);
                    flatOptionsText.push(dest.value)
                })
            })
            setDestinationOptions(filterOptions);
            setFlatDestinationOptions(flatOptions);
        }
        if (destinations && watchedType !== "music") {
            const destinationTypesAux = [...PhoneUtil.DESTINATION_TYPES_OPTIONS];
            let options;
            destinationTypesAux.forEach((destinationType) => {
                const { key } = destinationType; 
                if(key === watchedType) {
                    options = destinations[key]?.map(({destination_id, label, user, alias}) => (
                            {key: destination_id, value: destination_id, text: (!user ? alias ? `${label} (Ext. ${alias})` : label : `${user.first_name} ${user.last_name} (Ext. ${alias})`)}
                        ))
                }
            })
            setDestinationOptionsDropdown(options);
        }
    }, [destinations, watchedType])

    useEffect(() => {
        if (usersWithExtensions) {
            setDestinationOptionsDropdown(usersWithExtensions.map(({first_name, last_name, user_id, extension_number})=>{
                return {key: user_id, value: user_id, text: `${first_name} ${last_name} ${extension_number ? `(Ext. ${extension_number})` : ''}` }
            }));
        }
    }, [usersWithExtensions,])

    useEffect(() => {
        if (!isReadingSound && sound) {
            setValue("label", sound.label);
            setValue("apply_to", sound?.sound_category === "music" 
                ? sound?.in_use?.map(destination => destination.alias ? `${destination.label} (Ext. ${destination.alias})` : destination.label) 
                        : sound?.sound_category === "vmdrop" 
                            ? sound?.in_use?.map(user => user.user_id)
                                : sound?.in_use?.map(destination => destination.destination_id));
            setValue("sound_category", sound.sound_category);
            setValue("label", sound.label);
            setValue("isEdit", true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sound])

    const getAudioType = (watchedType) => {
        switch(watchedType){
            case "ivr":
                return "routing_rule,destination_group,conference_room,parking_lot,call_queue,ring_group,direct_extension,personal_voicemail,company_voicemail,forwarding_number"
            case "personal_voicemail":
                return "routing_rule,destination_group,conference_room,parking_lot,call_queue,ring_group,direct_extension,forwarding_number,company_voicemail,ivr"
            case "company_voicemail":
                return "routing_rule,destination_group,conference_room,parking_lot,call_queue,ring_group,direct_extension,forwarding_number,personal_voicemail,ivr"
            case "music":
                return "routing_rule,destination_group,ring_group,direct_extension,forwarding_number,personal_voicemail,company_voicemail,ivr"
            default:
                return []
        }   
    }
    
    useEffect(() => {
        watchedType === "vmdrop" ?
            dispatch(pbxConfigActions.getUsersWithExtensions())
            :
            dispatch(pbxConfigActions.getDestinations({exclude: getAudioType(watchedType)}))
    }, [dispatch, watchedType])


    return (
        <Modal open={open} onClose={onCloseModal} size="small"> 
            <Modal.Header>
                {isEditSoundsModal ? 'Edit' : 'Create'} Audio
            </Modal.Header>
            <Modal.Content>
                <Form noValidate loading={isReadingDestinations || isReadingSound}>
                    <div className="AudioModal__form">
                        <div className="AudioModal__controllerContainer">
                            <Controller
                                name='sound_category'
                                control={control}
                                style={{flex: 1}}
                                render={({ field: { name, value, onChange } }) => (
                                    isEditSoundsModal && getValues("sound_category") === "ivr" ?
                                        <Form.Input
                                            value={"IVR"}
                                            label="Type"
                                            required
                                            readOnly={!!isEditSoundsModal}
                                        />
                                        :
                                        <Form.Select
                                            name={name}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            options={soundTypes}
                                            label="Type"
                                            placeholder="Select type"
                                            required
                                        />
                                )}
                            />
                            <Controller
                                name='label'
                                control={control}
                                render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                                    <Form.Input
                                        name={name}
                                        value={value}
                                        onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                        label="Label"
                                        required
                                        error={error?.message}
                                        placeholder="Type audio label"
                                        onBlur={onCheckDuplicatedName}
                                    />
                                )}
                            />
                        </div>
                        <div className="AudioModal__controllerContainer" style={{marginBottom: 15}}>
                            {(watchedType === "company_voicemail" || watchedType === "personal_voicemail" || watchedType === "ivr" || watchedType === "vmdrop") &&
                                <Controller
                                    name='apply_to'
                                    control={control}
                                    render={({ field: { name, value, onChange }}) => (
                                        <Form.Select
                                            label="Apply To"
                                            options={destinationOptionsDropdown}
                                            value={value}
                                            onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                            name={name}
                                            multiple
                                            search
                                            className={watchedType === "ivr" && isEditSoundsModal ? "disable--selects" : ""}
                                        />
                                    )}
                                />}
                            {(watchedType && watchedType === "music") &&                                     
                                <Controller 
                                    name='apply_to'
                                    control={control}
                                    render={({ field: { name, value, onChange }}) => (
                                        <Form.Field
                                            control={MultipleNestedDropdown}
                                            values={value}
                                            loading={isReadingDestinations}
                                            nested_options={destinationOptions.map(destination => ({
                                                ...destination,
                                                options: destination.options.map(option => ({
                                                    ...option,
                                                    disabled: value.includes(option.value)
                                                }))}))
                                            }
                                            onChange={(_, { value }) => {
                                                onChange({ target: { name, value } });
                                            }}
                                            placeholder="Select apply to"
                                            selection
                                            label="Apply To"
                                            pointing='top'
                                            onClickParent={() => {}}
                                        />
                                    )}
                                />
                            }
                            </div>
                        </div>
                        <div className="AudioModal__controllerContainer">
                        {isEditSoundsModal ?
                            <>
                                <div style={{ marginBottom: 5 }}><b>Audio <span className="AudioModal__controllerContainer--Attachment--required">*</span></b>
                                    <div style={{ float: 'right' }}>
                                        <Popup
                                            trigger={<Icon className='fas fa-circle-info' color='primary' />}
                                            content={'Allowed files include .wav and .mp3'}
                                            inverted
                                            hoverable
                                            position="top center"
                                        />
                                    </div>
                                </div>
                                <VoicemailPlayback
                                    className={`audio-playback-${sound.id}`}
                                    fileName={sound.label}
                                    link={sound.url}
                                    border
                                />
                            </>
                            :
                            <Controller
                                name="attachments"
                                control={control}
                                render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                                    <Form.Field
                                        className='Template__attachments-section'
                                        control={Input}
                                        error={error && error.message && {
                                            content: error.message,
                                            pointing: 'below'
                                        }}
                                    >
                                        <Segment style={{ width: '100%' }}>
                                            <div>
                                                <Icon onClick={() => { dropzoneRef.current.open() }} color='blue' name='plus circle' link /> <b>Attach file <span className="AudioModal__controllerContainer--Attachment--required">*</span></b>
                                                <div style={{ float: 'right' }}>
                                                    <Popup
                                                        trigger={<Icon className='fas fa-circle-info' color='primary' />}
                                                        content={'Allowed files include .wav and .mp3'}
                                                        inverted
                                                        hoverable
                                                        position="top center"
                                                    />
                                                </div>
                                            </div>
                                            <FileInput
                                                onChange={(_, { value: new_value }) => {
                                                    if (new_value.length <= 1 && value <= 1) {
                                                        onChange({
                                                            target: {
                                                                name, value: [
                                                                    ...value,
                                                                    ...new_value.filter(new_file => (
                                                                        value.findIndex(file => file.path === new_file.path) === -1
                                                                    ))
                                                                ]
                                                            }
                                                        })
                                                    } else {
                                                        Notification.alert("One audio file limit", STATUS_TYPES.ERROR, true);
                                                    }
                                                }}
                                                onReject={(rejected) => {
                                                    if (rejected.length > 0) {
                                                        Notification.alert("File must be an audio file", STATUS_TYPES.ERROR, true);
                                                    }
                                                }}
                                                onRemove={(_, { value }) => { onChange({ target: { name, value } }) }}
                                                files={value}
                                                name="files"
                                                accept={['audio/mpeg', 'audio/wav']}
                                                icon="fa-solid fa-music"
                                                size="mini"
                                                dropzoneRef={dropzoneRef}
                                                showIcon
                                                hasBrowse

                                            />
                                            <i>Remaining: {FileUtils.getRemainingSize(value, "phone_system")} MB </i> {error?.message && `(${error.message})`}
                                        </Segment>
                                    </Form.Field>
                                )}
                            />}
                    </div>
                </Form>
            </Modal.Content>
            <Modal.Actions 
                hasSaveButton
                onSave={() => isEditSoundsModal ? onEditAudio(sound.id) : onAddAudio()}
                saveDisabled={!isValid || !isDirty}
                saveOptions={{ loading: isReadingDestinations || isReadingUsersWithExtensions || isUploading }} 
            />  
        </Modal>
    )
}

export default Audio;

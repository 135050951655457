export const KEY_CREATED = "created";
export const KEY_CREATED_BY = "created_by";
export const KEY_LAST_MODIFIED = "last_modified";
export const KEY_LAST_MODIFIED_BY = "last_modified_by";
export const KEY_PROPOSAL_INVOICE_CREATED = "proposal_invoice_created";
export const KEY_QUOTE_ORDER_ID = "id";

export const KEY_EXPIRES_ON = "expires_on";
export const KEY_OWNER = "Owner";
export const KEY_OWNED_BY = "Owned By";
export const KEY_NUMBER_PRODUCTS_BUNDLES = "# Products/Bundles";
export const KEY_PRODUCTS_BUNDLES = "Products/Bundles";
export const KEY_CONTACT_OWNER = "Contact Owner";
export const KEY_CONTACT_CO_OWNER = "Contact Co-Owner";
export const KEY_ACCOUNT_OWNER = "Account Owner";
export const KEY_ACCOUNT_CO_OWNER = "Account Co-Owner";
export const KEY_CONTACT_NAME = "contact_id";

export const KEY_ACCOUNT_NAME = "account_id";
export const KEY_ACCOUNT_STATUS = "Account Status";
export const KEY_OPPORTUNITY_NAME = "opportunity_id";
export const KEY_OPPORTUNITY_OUTCOME = "Opportunity Outcome";
export const KEY_SUBTOTAL = "subtotal";
export const KEY_STATUS = "status";

import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Navigate, Outlet, useLocation } from "react-router";

import Activity from "shared/Activity";
import ProfileDetails from "pages/ContactProfile/ProfileDetails";

import Tabs from "./Tabs";

import usersActions from "actions/users";
import groupsActions from "actions/groups";
import contactActions from "actions/contact";
import onReadContactInformationDispatch from "shared/onReadContactInfoDispatch";
import { Error404, Error500 } from "pages/ErrorPages";
import { Breadcrumb, Dimmer, Loader, Segment } from "semantic-ui-react";
import StickyNotes from "shared/StickyNotes";
import taskTypesActions from "actions/task_types";
import { RecordProfile, STATUS_TYPES, Notification, DateTimeUtils } from "dyl-components";
import { ProfileHeader } from "./ProfileHeader";
import { NavLink } from "react-router-dom";

import "./index.scss";
import { StringUtils } from "utils";
import recentsActions from "actions/recents";
import { FAVORITE_TYPE } from "shared/constants/FAVORITE_TYPE";
import { RecordInfo } from "shared/RecordInfo";
import QuoteBuilderProvider from "shared/context/QuoteBuilderProvider";

const ContactProfile = ({
    contact_id,
    contact,
    isInHotlist,
    onHotlistToggle,

    account_stage
}) => {
    const [isSidePanelOpen, setSidePanelOpen] = useState(true);
    const { pathname } = useLocation();
    const tab = pathname.split("/")[3];

    const isDetailsSectionOpen = tab === undefined || tab === "";
    const { suffix, first_name, last_name } = contact;

    const created = contact?.created ? DateTimeUtils.formatEpoch(contact.created, DateTimeUtils.WORD_DATETIME_FORMAT) : '';
    const last_modified = contact?.last_modified ? DateTimeUtils.formatEpoch(contact.last_modified, DateTimeUtils.WORD_DATETIME_FORMAT) : '';

    const contact_name =
        `${first_name} ${last_name}` + (suffix ? ` ${suffix}` : ``);

    const link = (() => {
        switch (account_stage) {
            case 'contact':
                return '/contacts';
            case 'lead':
                return '/leads';
            case 'opportunity':
                return '/opportunities';
            case 'customer':
                return '/customers';
            default:
                return '/contacts';
        }
    })();

    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(contactActions.readContact(contact_id));
    };
    return (
        <RecordProfile
            isDetailsSectionOpen={isDetailsSectionOpen}
            header={
                <ProfileHeader
                    id={Number(contact_id)}
                    name={contact_name}
                    isInHotlist={isInHotlist}
                    onHotlistToggle={onHotlistToggle}
                    breadcrumbs={
                        <Breadcrumb>
                            <Breadcrumb.Section
                                as={NavLink}
                                to={link}
                                link
                            >
                                {StringUtils.capitalize(account_stage)}
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider />
                            <Breadcrumb.Section active>
                                {contact_name}
                            </Breadcrumb.Section>
                        </Breadcrumb>
                    }
                    isSidePanelOpen={isSidePanelOpen}
                />
            }
            details={<ProfileDetails />}
            tabs={<Tabs />}
            tabContent={<Outlet />}
            rightHandContent={
                <React.Fragment>
                    <StickyNotes record_id={contact_id} />
                    <Segment>
                        <RecordInfo
                            created={created}
                            last_modified={last_modified}
                            assignees={contact.assignees}
                            record_id={contact_id}
                            refresh={refresh}
                        />
                    </Segment>
                    <Activity />
                </React.Fragment>
            }
            onToggleSidePanel={(isOpen) => {
                setSidePanelOpen(isOpen);
            }}
            isSidePanelOpen={isSidePanelOpen}
        />
    );
};

const ContactProfileContainer = () => {
    const params = useParams();
    const contact_id = Number(params.contact_id);
    const [isInHotlist, setIsInHotlist] = useState(false);

    const { contact, contactError, contactsInHotlist, current_stage, isReadingContact } = useSelector((state) => ({
        current_stage: state.contact.current_stage,
        isReadingContact:
            state.contact.isReadingContact ||
            state.contact_phone.isReadingPhones ||
            state.contact_email.isReadingEmails,
        contact: state.contact.contact,
        contactError: state.contact.contactError,
        contactsInHotlist: state.groups.contactsInHotlist
    }))

    const dispatch = useDispatch();

    useEffect(() => {
        (onReadContactInformationDispatch(dispatch))(contact_id);
        dispatch(usersActions.readUsers());
        dispatch(taskTypesActions.readTaskTypes());
        dispatch(groupsActions.readContactGroups(contact_id));
        dispatch(groupsActions.checkHotlist({ contacts: [contact_id] }));
        dispatch(recentsActions.addRecent({
            external_id: contact_id,
            page_type: FAVORITE_TYPE.CONTACT
        }))
    }, [contact_id, dispatch]);

    useEffect(() => {
        setIsInHotlist(contactsInHotlist.includes(Number(contact_id)));
    }, [contactsInHotlist, contact_id])

    // Just making things backwards compatible while we figure the new contact page layout
    if (!contact_id) {
        const { contact_id } = queryString.parse(window.location.search);
        return <Navigate to={`/contact/${contact_id}`} />;
    }

    if (isReadingContact) {
        return (
            <Dimmer active>
                <Loader active />
            </Dimmer>
        );
    }

    if (!isReadingContact && contactError) {
        return <Error500 message={"Something went wrong"} />;
    }

    if (!isReadingContact && !contact) {
        return <Error404 message={"Contact not found"} />;
    }

    const onHotlistToggle = async () => {
        try {
            if (isInHotlist) {
                await dispatch(groupsActions.removeHotlist(contact_id));
                await dispatch(groupsActions.checkHotlist({ contacts: [contact_id] }));
                Notification.alert('Successfully removed contact from hotlist!', STATUS_TYPES.SUCCESS, true);
            } else {
                await dispatch(groupsActions.addToHotlist({ contact_id: [contact_id] }));
                await dispatch(groupsActions.checkHotlist({ contacts: [contact_id] }));
                Notification.alert('Successfully added contact to hotlist!', STATUS_TYPES.SUCCESS, true);
            }
        } catch (error) {
            console.log(error)
            if (isInHotlist) {
                Notification.alert('Problem removing contact from hotlist!', STATUS_TYPES.ERROR, true);
            } else {
                Notification.alert('Problem adding contact to hotlist!', STATUS_TYPES.ERROR, true);
            }
        }
    }

    const account_stage = (() => {
        if (current_stage?.account_stage === 'customer') {
            return 'contact';
        }
        return current_stage?.account_stage || 'contact';
    })();

    return (
        <ContactProfile
            contact={contact}
            contact_id={contact_id}
            onReadContactInformation={onReadContactInformationDispatch(dispatch)}
            account_stage={account_stage}
            isInHotlist={isInHotlist}
            onHotlistToggle={onHotlistToggle}
        />
    );
};

const Container = () => {
    const dispatch = useDispatch();

    const params = useParams();
    const contact_id = Number(params.contact_id);

    return (
        <QuoteBuilderProvider refreshAccountInformation={() => {
            return dispatch(contactActions.readContact(contact_id));
        }}>
            <ContactProfileContainer />
        </QuoteBuilderProvider>
    )
}

export default Container;

import React, { useContext, useRef, useState } from 'react';
import { DateTimeUtils, EllipsisDropdown, Table, ControlledPopup, EllipsisDropdownPortal, Notification, STATUS_TYPES } from 'dyl-components';
import Contact from 'shared/Contact';
import { Link, useSearchParams } from 'react-router-dom';
import { Dropdown, Icon, Checkbox, Popup, Header, Ref, Loader, Dimmer } from 'semantic-ui-react';

import {
    KEY_CREATED_ON, KEY_OWNED_BY, KEY_LAST_MODIFIED,
    KEY_CONVERT, KEY_ACCOUNT,
    KEY_ACTIONS
} from './constants';
import AccountLink from 'shared/AccountLink';
import { BulkActionsContext } from 'shared/context/BulkActionsProvider';
import ContactConversionControls from 'shared/ContactConversionControls';
import contactsActions from 'actions/contacts';
import { useDispatch, useSelector } from 'react-redux';
import MoveContactPopup from '../../Groups/subcomponents/MoveContactPopup'
import groupsActions from 'actions/groups';

const DropdownItem = ({ text, icon, ...otherProps }) => {
    return (
        <Dropdown.Item text={<React.Fragment>
            <Icon name={icon} />
            {text}
        </React.Fragment>}
            style={{ cursor: 'pointer' }}
            {...otherProps}
        />
    );
}

export const ContactsRow = ({ contact, isColumnHidden, isInGroups, group_id, parent }) => {
    const [selectedContacts, setSelectedContacts] = useContext(BulkActionsContext);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const dispatch = useDispatch();

    const [params] = useSearchParams();

    const GROUPS_ELLIPSIS_OPTIONS = [
        {
            id: 1,
            text: 'Move contact',
            icon: 'fa-solid fa-folder-arrow-down',
            onClick: () => setIsPopupOpen(true)
        },
        {
            id: 2,
            text: parent?.name ? 'Remove from subgroup' : 'Remove from group',
            icon: 'fa-solid fa-trash',
            onClick: async () => {
                try {
                    await dispatch(groupsActions.deleteContactGroup(contact.id, { group_id }));
                    Notification.alert("Successfully removed contact from group!", STATUS_TYPES.SUCCESS);
                    dispatch(groupsActions.readGroupContacts(group_id, Object.fromEntries(params)));
                } catch (e) {
                    Notification.alert("Failed to remove contact from group", STATUS_TYPES.ERROR);
                }
            }
        },
    ];

    const isSelected = (contact_id) => {
        return selectedContacts.includes(contact_id);
    }

    const onToggleContact = (contact_id) => {
        const newSelectedContacts = isSelected(contact_id) ? selectedContacts.filter(id => contact_id !== id) : [...selectedContacts, contact_id];
        setSelectedContacts(newSelectedContacts);
    }
    
    const contactBeingRemovedFromGroup = useSelector(state => state.groups.contactBeingRemovedFromGroup)

    const refresh = () => {
        if (!isInGroups) {
            dispatch(contactsActions.readContacts({ ...Object.fromEntries(params), limit: 25 }));
        } else {
            dispatch(groupsActions.readGroupContacts(group_id, Object.fromEntries(params)));
        }
    }

    const ref = useRef();

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox checked={isSelected(contact.id)} onChange={() => { onToggleContact(contact.id) }} />
            </Table.Cell>
            {
                !isColumnHidden(KEY_CREATED_ON) &&
                <Table.Cell >
                    <div><b>{DateTimeUtils.formatEpoch(contact.created_ts, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{DateTimeUtils.formatEpoch(contact.created_ts, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_LAST_MODIFIED) &&
                <Table.Cell>
                    <div><b>{DateTimeUtils.formatEpoch(contact.activity, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                    <div>{DateTimeUtils.formatEpoch(contact.activity, DateTimeUtils.TIME_FORMAT)}</div>
                </Table.Cell>
            }
            <Table.Cell>
                <Contact
                    contact={contact}
                    name={`${contact.first_name} ${contact.last_name}`}
                    linkToContact={<Link to={`/contact/${contact.id}`}>{`${contact.first_name} ${contact.last_name}`}</Link>} />
            </Table.Cell>
            {
                !isColumnHidden(KEY_ACCOUNT) &&
                <Table.Cell>
                    {contact?.account?.account_type &&
                        <AccountLink
                            id={contact.account.id}
                            name={contact.account.name}
                            type={contact.account.account_type}
                        />
                    }
                </Table.Cell>
            }

            {
                !isColumnHidden(KEY_OWNED_BY) &&
                <Table.Cell>
                    Unassigned
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_CONVERT) &&
                <Table.Cell>
                    {!(contact.id === contact.account.pipeline_person_id) && (
                        <ContactConversionControls
                            account_id={contact.account.id}
                            account_stage={contact.module?.account_stage || 'contact'}
                            contactName={`${contact.first_name} ${contact.last_name}`}
                            contact_id={contact.id}
                            linkedAccountIsACustomer={contact.account.customer}
                            linkedAccountName={contact.account.name}
                            linkedAccountPrimaryContact={contact.account.primary_person_id}
                            refresh={refresh}
                            pipeline_id={contact?.module?.id}
                        />
                    )}
                </Table.Cell>
            }
            {
                !isColumnHidden(KEY_ACTIONS) &&
                <Table.Cell width={1}>
                    <Dimmer active={contactBeingRemovedFromGroup === contact.id}>
                        <Loader active={contactBeingRemovedFromGroup === contact.id} />
                    </Dimmer>
                    {isInGroups ? (
                        <Popup
                            trigger={
                                <EllipsisDropdown {...isPopupOpen ? { isDropdownOpen: false } : {}}>
                                    {GROUPS_ELLIPSIS_OPTIONS.map(({ text, icon, disabled, onClick, ...otherProps }, idx) => {
                                        return idx === 1 ? (
                                            <ControlledPopup
                                                header={
                                                    <Header as='h4' textAlign='center'>
                                                        Are you sure?
                                                        <Header.Subheader style={{ marginTop: 5 }}>
                                                            You cannot undo delete actions.
                                                        </Header.Subheader>
                                                    </Header>
                                                }
                                                trigger={<Dropdown.Item icon={icon} text={text} disabled={disabled} />}
                                                onConfirm={onClick}
                                                inverted
                                            />
                                        ) : (
                                            <DropdownItem
                                                key={idx}
                                                text={text}
                                                icon={icon}
                                                disabled={disabled}
                                                onClick={onClick}
                                                {...otherProps}
                                            />
                                        );
                                    })}
                                </EllipsisDropdown>
                            }
                            content={
                                <MoveContactPopup
                                    handlePopupClose={() => setIsPopupOpen(false)}
                                    contact_id={contact.id}
                                    group_id={group_id}
                                />
                            }
                            on='click'
                            position='bottom left'
                            open={isPopupOpen}
                            onClose={() => setIsPopupOpen(false)}
                            flowing
                        />
                    ) : (
                        <Ref innerRef={ref}>
                            <EllipsisDropdownPortal reference={ref}>
                                {!isInGroups && [
                                    <DropdownItem
                                        key={'sequences'}
                                        text={'Sequences'}
                                        icon={'fas fa-sitemap'}
                                    />
                                ]}
                            </EllipsisDropdownPortal>
                        </Ref>
                    )}
                </Table.Cell>
            }
        </Table.Row>
    );
}

import quoteActions from "actions/quote";
import {
    DateTimeUtils,
    Modal,
    Notification,
    STATUS_TYPES,
} from "dyl-components";
import { useContext, useEffect } from "react";
import {
    Controller,
    useController,
    useFieldArray,
    useFormContext,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "semantic-ui-react";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import Quote from "shared/forms/Quote";
import companyActions from "actions/company";
import ConvertToCustomerOrder from "./ConvertToCustomerOrder";

const EditQuote = ({
    opportunity_id,
    account_id,
    isLinkedToCustomer,
    contact_id,
    account_primary_contact_id
}) => {
    const {
        control,
        formState: { isValid, isDirty },
        handleSubmit,
    } = useFormContext();

    const { fields: cart, replace } = useFieldArray({
        control,
        name: "cart",
        keyName: "key",
    });

    const quote = useSelector((state) => state.quote.quote);
    const disabled = !quote?.quote_active;
    const isSaving = useSelector(
        (state) => state.quote.isCreatingVersion || state.order.isCreating
    );

    const dispatch = useDispatch();

    const {
        quoteBuilderConfig,
        onOpenAddProducts,
        onEditQuote,
        onViewProposal,
    } = useContext(QuoteBuilderContext);
    const { id: quote_id } = quoteBuilderConfig;

    const updateQuote = (data) => {
        return dispatch(
            quoteActions.createVersion(
                {
                    cart_item_summary: data.cart
                        .filter((item) => !Boolean(item.removed))
                        .map((item) => {
                            const productDetails = quote.quote_summary.find(
                                (quoteItem) =>
                                    quoteItem.product_id === item.id &&
                                    quoteItem.product_variation_id ===
                                        item.variation_id
                            );

                            const price = (() => {
                                const isOneTime =
                                    !productDetails.price_data?.model?.includes(
                                        "recurring"
                                    );
                                const pricingModel =
                                    productDetails.price_data.model;
                                if (isOneTime) {
                                    if (!pricingModel.includes("volume")) {
                                        return pricingModel === "usage"
                                            ? productDetails.price_data.price
                                                  .price
                                            : productDetails.price_data.price;
                                    }
                                    return (
                                        productDetails.price_data.price.find(
                                            (range) =>
                                                item.quantity >= range.start &&
                                                item.quantity <=
                                                    (range.end ||
                                                        Number.POSITIVE_INFINITY)
                                        )?.price || 0
                                    );
                                }
                                if (!pricingModel.includes("volume")) {
                                    return productDetails.price_data.price[
                                        item.pricing_schedule
                                    ].price;
                                }
                                const frequency =
                                    productDetails.price_data.price[
                                        item.pricing_schedule
                                    ].volumes;
                                return (
                                    frequency.find(
                                        (range) =>
                                            item.quantity >= range.start &&
                                            item.quantity <=
                                                (range.end ||
                                                    Number.POSITIVE_INFINITY)
                                    )?.price || 0
                                );
                            })();

                            return {
                                addons: item.addons.map((addon_id) => ({
                                    addon_id,
                                    quantity: Number(item.quantity),
                                    price: productDetails.addon.find(
                                        (addon) => addon.id === addon_id
                                    ).current_price,
                                })),
                                item: {
                                    additional_price:
                                        productDetails.product_additional_price ||
                                        0,
                                    discount: item.discount || 0,
                                    price: price,
                                    price_schedule: item.pricing_schedule,
                                    product_id: item.id,
                                    product_variation_id: item.variation_id,
                                    quantity: Number(item.quantity),
                                },
                                taxFees: [
                                    ...productDetails.fee.map((fee) => ({
                                        amount: fee.current_amount,
                                        fee: true,
                                        percent: fee.tax_fee_percent,
                                        tax_id: fee.id,
                                    })),
                                    ...productDetails.tax.map((tax) => ({
                                        amount: tax.current_amount,
                                        fee: false,
                                        percent: tax.tax_fee_percent,
                                        tax_id: tax.id,
                                    })),
                                ],
                            };
                        }),
                    quote_information: {
                        account_id,
                        contact_info: {
                            location: {
                                email: data.contact_email,
                                contact_id: data.contact_id,
                                phone: data.contact_phone,
                                address: data.contact_address
                            },
                            ship_to: {
                                first_name: quote.first_name,
                                last_name: quote.last_name,
                                suffix: quote.suffix
                            }
                        },
                        expires: DateTimeUtils.getUnixTime(
                            data.expires,
                            DateTimeUtils.WORD_DATE_FORMAT
                        ),
                        from_information: {
                            email: data.from_email,
                            from_user_id: data.from_user_id,
                            phone: data.from_phone,
                            profile_info: data.profile_info,
                        },
                        name: data.name,
                        note: data.notes,
                        quote_status: data.quote_status,
                    },
                },
                { opportunity_id },
                quote_id
            )
        );
    };

    const onCreateProposal = async (data) => {
        try {
            const version_id = await updateQuote(data);
            Notification.alert(
                "Successfully created proposal",
                STATUS_TYPES.SUCCESS
            );
            onViewProposal(version_id);
            dispatch(quoteActions.getOpportunityRecentQuote(opportunity_id));
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to create proposal", STATUS_TYPES.ERROR);
        }
    };

    useEffect(() => {
        dispatch(companyActions.readCompany());
    }, [dispatch]);

    const onSaveQuote = async (e) => {
        try {
            await handleSubmit(updateQuote)(e);
            Notification.alert(
                "Successfully updated quote",
                STATUS_TYPES.SUCCESS
            );
            dispatch(quoteActions.getOpportunityRecentQuote(opportunity_id));
            onEditQuote(quote_id);
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to update quote", STATUS_TYPES.ERROR);
        }
    };

    const { field: contactEmailField } = useController({
        name: "contact_email",
        control,
    });

    const { field: pricingScheduleField } = useController({
        name: "pricing_schedule",
        control
    })

    return (
        <>
            <Modal.Content scrolling>
                <Form size="small" noValidate>
                    <Quote quote={quote} />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    basic
                    onClick={() => {
                        replace(cart.filter((item) => !Boolean(item.removed)).map(item => {
                            const productDetails = quote.quote_summary.find(
                                (quoteItem) =>
                                    quoteItem.product_id === item.id &&
                                    quoteItem.product_variation_id ===
                                        item.variation_id
                            );
                            if (!Boolean(productDetails?.price_data?.model?.includes("recurring"))) {
                                return item;
                            }
                            const currentPricingSchedule = item.pricing_schedule;
                            if (Boolean(productDetails.price_data?.price[currentPricingSchedule]?.active)) {
                                return item;
                            }
                            const newPricingSchedule = Object.keys(productDetails.price_data.price).filter(frequency => productDetails.price_data.price[frequency]?.active)[0];
                            return {
                                ...item,
                                pricing_schedule: newPricingSchedule
                            }
                        }));
                        if (pricingScheduleField.value === "semi-annually") {
                            pricingScheduleField.onChange({
                                target: {
                                    name: pricingScheduleField.name,
                                    value: "semi_annually"
                                }
                            })
                        }
                        onOpenAddProducts({ quote_id });
                    }}
                    type="button"
                    color="primary"
                    disabled={isSaving || disabled}
                >
                    Back to Add Products
                </Button>
                <Controller
                    control={control}
                    name="name"
                    render={({ field: { name, value, onChange } }) => (
                        <Button
                            disabled={!isValid || isSaving || disabled}
                            onClick={() => {
                                onChange({
                                    target: {
                                        name,
                                        value: `Duplicate of ${value}`,
                                    },
                                });
                                replace(
                                    cart.filter(
                                        (item) => !Boolean(item.removed)
                                    ).map(item => {
                                        const productDetails = quote.quote_summary.find(
                                            (quoteItem) =>
                                                quoteItem.product_id === item.id &&
                                                quoteItem.product_variation_id ===
                                                    item.variation_id
                                        );
                                        if (!Boolean(productDetails?.price_data?.model?.includes("recurring"))) {
                                            return item;
                                        }
                                        const currentPricingSchedule = item.pricing_schedule;
                                        if (Boolean(productDetails.price_data?.price[currentPricingSchedule]?.active)) {
                                            return item;
                                        }
                                        const newPricingSchedule = Object.keys(productDetails.price_data.price).filter(frequency => productDetails.price_data.price[frequency]?.active)[0];
                                        return {
                                            ...item,
                                            pricing_schedule: newPricingSchedule
                                        }
                                    })
                                );
                                onEditQuote(null);
                            }}
                            basic
                            type="button"
                            color="primary"
                        >
                            Create New Quote
                        </Button>
                    )}
                />
                <ConvertToCustomerOrder
                    account_id={account_id}
                    account_primary_contact_id={account_primary_contact_id}
                    contact_id={contact_id}
                    opportunity_id={opportunity_id}
                    isLinkedToCustomer={isLinkedToCustomer}
                    disabled={disabled}
                />
                <Button
                    basic
                    disabled={!isValid || !isDirty || isSaving || disabled}
                    loading={isSaving}
                    type="button"
                    color="primary"
                    onClick={onSaveQuote}
                >
                    Save
                </Button>
                <Button
                    disabled={!isValid || isSaving || disabled}
                    loading={isSaving}
                    onClick={handleSubmit(onCreateProposal)}
                    basic
                    type="button"
                    color="primary"
                >
                    Create Proposal
                </Button>
                <Button
                    disabled={
                        !isValid ||
                        isSaving ||
                        !Boolean(contactEmailField.value) ||
                        disabled
                    }
                    type="button"
                    color="primary"
                >
                    Send
                </Button>
            </Modal.Actions>
        </>
    );
};

export default EditQuote;
